import moment from 'moment';
import addressesImportLogWrapper from 'api/direct-mail/utils/backend-wrappers/addressesImportLogWrapper';
import addressImportResWrapper from 'api/direct-mail/utils/backend-wrappers/addressImportResWrapper';
import awsIoTConnect from 'api/utils/awsIoTConnect';

import {
    UPLOADING_ADDRESSES,
    SET_ADDRESSES_IMPORT_SUCCESS,
    SET_ADDRESSES_IMPORT_ERROR,
    GET_ADDRESS_IMPORTS_LOG_LOADING,
    GET_ADDRESS_IMPORTS_LOG,
    DELETE_ADDRESSES_FILE,
    DELETE_ADDRESSES_FILE_LOADING,
    SET_ADDRESSES_UPLOAD_PROGRESS,
    UPLOAD_ADDRESSES_FILE,
    DOWNLOAD_ADDRESSES_FILE,
    UPLOAD_ADDRESSES_SUCCESS
} from './types';

import {
    notifyGetAddressImportsLogError,
    notifyDeleteAddressesFileError,
    notifyDeleteAddressesFileSuccess,
    notifyDownloadAddressesFileError,
} from './notificationActions';

import { 
    deleteImportedFile,
    downloadImportedFile,
    getImportsLog,
    uploadAddresses
} from 'api/direct-mail/addressesImport';

import userSession from 'utils/userSession';
import { subscribeToAwsIot } from 'api/direct-mail/awsIot';

export const getAddressesImportsLog = (params) => async dispatch => {
    dispatch(notifyGetAddressImportsLogError({ display: false }));

    dispatch({
        type: GET_ADDRESS_IMPORTS_LOG_LOADING,
        payload: true,
    });

    const [importsLogErr, importsLog] = await getImportsLog(params);

    if(importsLogErr) {
        dispatch(notifyGetAddressImportsLogError({ serverError: importsLogErr }));
    }

    dispatch({
        type: GET_ADDRESS_IMPORTS_LOG_LOADING,
        payload: false,
    });

    return dispatch({
        type: GET_ADDRESS_IMPORTS_LOG,
        payload: importsLog
        ?.map((log) => addressesImportLogWrapper(log))
        .sort((a, b) => {
            return (
                new moment(b.createdDate).valueOf() -
                new moment(a.createdDate).valueOf()
            );
        }),
    });
};

export const uploadAddressesFile = (file = null, iotEndpoint = null) => async (dispatch) => {
    if (!file) {
        dispatch(setUploadingAddresses(false));
        dispatch(setAddressesImportSuccess(false));
        dispatch(setAddressesImportError(true));
        return;
    }

    dispatch(setUploadingAddresses(true));
    dispatch(setAddressesImportSuccess(false));
    dispatch(setAddressesImportError(false));

    const [, uploadRes] = await uploadAddresses({ 
        file, 
        iotEndpoint, 
        onUploadProgress: uploadPercentage => {
            dispatch({
                type: SET_ADDRESSES_UPLOAD_PROGRESS,
                payload: uploadPercentage
            });
        }
    });

    return dispatch({
        type: UPLOAD_ADDRESSES_FILE,
        payload: addressImportResWrapper(uploadRes)
    });
};

export const initializeAwsIoT = async dispatch => {

    const [initErr, initRes] = await subscribeToAwsIot();

    if(initErr) {
        return null;

    } else {
        awsIoTConnect.connect(
            `imports-${userSession.getUserId()}`, 
            initRes.iotEndpoint,
            initRes.region,
            initRes.accessKey,
            initRes.secretKey,
            initRes.sessionToken
        );

        awsIoTConnect.onMessageCallback = ({success,message,error}) => {
            console.log("message",message);
            if(success) {
                dispatch(setUploadingAddresses(false));
                dispatch(setAddressesImportError(false));
                dispatch(setAddressesImportSuccess(true));
                dispatch({
                    type: UPLOAD_ADDRESSES_SUCCESS,
                    payload: { messageObj: message },
                });
            } else {
                console.log("error",error);
                dispatch(setUploadingAddresses(false));
                dispatch(setAddressesImportError(true));
            }

            dispatch({
                type: SET_ADDRESSES_UPLOAD_PROGRESS,
                payload: 0
            });
        };

        awsIoTConnect.onCloseCallback = () => {
            dispatch(setUploadingAddresses(false));
            dispatch(setAddressesImportError(true));
        }

        return initRes.iotEndpoint;
    }
};


export const setUploadingAddresses = (value = false) => {
    return {
        type: UPLOADING_ADDRESSES,
        payload: value,
    };
};

export const setAddressesImportSuccess = (value = null) => {
    return {
        type: SET_ADDRESSES_IMPORT_SUCCESS,
        payload: value,
    };
};

export const setAddressesImportError = (value = null) => {
    return {
        type: SET_ADDRESSES_IMPORT_ERROR,
        payload: value,
    };
};

export const deleteAddressesFile = (importLogId = null) => async dispatch => {
    dispatch(notifyDeleteAddressesFileSuccess({ display: false }));
    dispatch(notifyDeleteAddressesFileError({ display: false }));

    dispatch({
        type: DELETE_ADDRESSES_FILE_LOADING,
        payload: true,
    });

    const [deleteFileErr, deletedFile] = await deleteImportedFile({
        importLogId: Number(importLogId)
    });

    if(deleteFileErr) {
        dispatch(notifyDeleteAddressesFileError({ serverError: deleteFileErr }));
    } else {
        dispatch(notifyDeleteAddressesFileSuccess({ 
            customConfigObj: { 
                totalAddresses: deletedFile.addressesDeleted,
                fileName: deletedFile.filename
            }
        }));
        dispatch(getAddressesImportsLog());
    }

    dispatch({
        type: DELETE_ADDRESSES_FILE_LOADING,
        payload: false,
    });

    return dispatch({
        type: DELETE_ADDRESSES_FILE,
        payload: deletedFile,
    });
};

export const downloadAddressesFile = (filename = null) => async dispatch => {

    const [downloadErr, downloadRes] = await downloadImportedFile({ filename });

    if(downloadErr) {
        dispatch(notifyDownloadAddressesFileError(null));
    } else {
        const link = document.createElement('a');
        link.href = downloadRes;
        link.setAttribute('download', `${filename}`);
        document.body.appendChild(link);
        link.click();
        
        return dispatch({
            type: DOWNLOAD_ADDRESSES_FILE,
            payload: downloadRes
        });
    }

};
